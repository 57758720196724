import $ from 'jquery';

export default class ViewTracker {

    static addViewListener() {
        $(window).on("beforeunload", () => {
            let index = document.elementFromPoint($(window).width()! - $(window).width()! / 8, $(window).height()! / 8)?.getAttribute("imageIndex") //May get non img
            if (!index) return; //TODO: Where's waldo (loop search for img)
            this.saveViewIndex(parseInt(index))
        });
    }

    static saveViewIndex(index: number) {
        document.cookie = "viewindex=" + index + "; expires=Wed, 01 Jan 2025 00:00:00 UTC; Secure=true; SameSite=Strict";
    }

    static getViewIndex(): number {
        let cookieStringValue: string | undefined = document.cookie
            .split("; ")
            .find((row) => row.startsWith("viewindex="))
            ?.split("=")[1];

        let cookieValue: number = 0

        if (cookieStringValue) cookieValue = parseInt(cookieStringValue);

        return cookieValue
    }

}