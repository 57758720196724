import $ from 'jquery';
import ViewTracker from './viewTracker'
import X from './pepperX'

let columns = 4;
let imagesToLoad = 40;

let imageIndex = 0;
let maxImageIndex = 2189;

window.scrollTo(0, 0);

$(function () {
  for (var i = 0; i < columns; i++) {
    $('#gallery .row').append($('<div class="column" id="column' + i + '"></div>'));
  }

  imageIndex = ViewTracker.getViewIndex();
  addImages();
  backHandler();
  ViewTracker.addViewListener();

  $(window).on("scroll", () => {
    document.documentElement.dataset.scroll = window.scrollY.toString(); //Reactive banner
    var shouldLoad = $(window).scrollTop()! + $(window).height()! > $(document).height()! - 800
    if (shouldLoad) {
      addImages();
    }
  });
});

function addImages() {
  for (var i = 0; i < imagesToLoad; i++) {
    var image = $('<img class="galleryImage" imageIndex="' + imageIndex + '" src="/images/' + X.gx(imageIndex) + '.webp">')
    $('#column' + (i % columns)).append(image);

    image.on("click", function () {
      window.history.pushState('image', 'image', '#');
      $('body').append('<div class=overlay><a href="' + $(this).attr('src') + '" target="_blank" class="fullScreen"></a><div id=galleryOverlay></div></div>');
      $('.fullScreen').append($(this).clone());
      $('.overlay').click(function () {
        history.back();
      });
    });

    imageIndex++;
    if (imageIndex > maxImageIndex) imageIndex = 0;
  }
}

function backHandler() {
  history.replaceState("", document.title, window.location.pathname + window.location.search);
  window.onpopstate = () => {
    let overlay = $('.overlay')
    if (overlay) {
      overlay.remove();
      return;
    }
  };
}

function getSmallestColumnHeight(): number { //Columns are stretched vertically...
  var minHeight: number = 0;
  for (var i = 0; i < columns; i++) {
    var columnHeight: number | undefined = $('#column' + i).height()
    if (columnHeight) {
      if (minHeight == 0 || columnHeight < minHeight) {
        minHeight = columnHeight
      }
    }
  }
  return minHeight;
}